import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "首页",
    mate: { rootPathName: "首页" },
    component: Home,
  },
  {
    path: "/about",
    name: "关于我们",
    mate: { rootPathName: "关于我们" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/canDo",
    name: "业务领域",
    mate: { rootPathName: "业务领域" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/canDo.vue"),
  },
  {
    path: "/teamInfo",
    name: "团队介绍",
    mate: { rootPathName: "团队介绍" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/teamInfo.vue"),
  },
  {
    path: "/member",
    name: "成员页",
    mate: { rootPathName: "成员页" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/member.vue"),
  },
  {
    path: "/classicCases",
    name: "经典案例",
    mate: { rootPathName: "经典案例" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/classicCases.vue"),
  },
  {
    path: "/lawNews",
    name: "法律资讯",
    mate: { rootPathName: "法律资讯" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/lawNews.vue"),
  },
  {
    path: "/article",
    name: "文章页",
    mate: { rootPathName: "文章页" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/article.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  //切换路由回到顶部，如使用vue-scrollto跳转页面则不需要
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, behavior: "smooth" }; //平滑滚动到顶部
    }
  },
});

export default router;
