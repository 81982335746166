import axios from "axios";
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: "https://minggui.gxxqy.cn",
  // baseURL: "http://test.minggui.com",
  timeout: 6000, // 请求超时时间
});

export default request;

export { request as axios };
