<template>
    <a-config-provider :locale="locale">
        <div id="app" class="custom-body">
            <a-row style="position: relative" v-if="ShowTopBanner" ref="testtop">
                <div
                        style="
            position: absolute;
            color: white;
            width: 45rem;
            font-size: 25px;
            font-weight: 600;
            top: 65%;
            left: 50%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            word-break: break-all;
          "
                        v-if="webconfig"
                        v-html="webconfig['24'].richText"
                >
                    <!-- 我们专注于代理公司事务、合同纠纷、婚姻家庭纠纷、刑事辩护
                    房地产纠纷、资产重组、担任企事业法律顾问；为您提供专业的 法律帮助。 -->
                </div>
                <img v-if="webconfig" :src="webconfig['3'].mediaPath" alt="" style="width: 100%"/>
            </a-row>
            <a-layout id="components-layout-demo-top" class="layout">
                <a-anchor>
                    <a-layout-header
                            style="
              top: 0;
              width: 100%;
              z-index: 999;
              box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.1);
            "
                    >
                        <a-row
                                type="flex"
                                justify="space-around"
                                align="middle"
                                style="height: 100%"
                                ref="referenceTop"
                                id="referenceTop"
                        >
                            <a-col :span="1"></a-col>
                            <a-col :span="7">
                                <div class="logo">
                                    <img
                                            src="../public/img/明桂/logo.svg"
                                            class="logoimg"
                                            style="height: 80%; width: 80%"
                                            alt=""
                                    />
                                </div>
                            </a-col>
                            <a-col :span="11">
                                <a-row :gutter="10" align="middle" style="font-size: 16px">
                                    <router-link :to="{ name: '首页' }" v-scroll-to="'#top'">
                                        <a-col :span="4" style="color: black">首页</a-col>
                                    </router-link>
                                    <router-link :to="{ name: '业务领域' }" v-scroll-to="'#ywly'">
                                        <a-col :span="4" style="color: black">业务领域</a-col>
                                    </router-link>
                                    <router-link :to="{ name: '团队介绍' }" v-scroll-to="'#tdjs'">
                                        <a-col :span="4" style="color: black">团队介绍</a-col>
                                    </router-link>
                                    <router-link :to="{ name: '经典案例' }" v-scroll-to="'#jdal'">
                                        <a-col :span="4" style="color: black">经典案例</a-col>
                                    </router-link>
                                    <router-link :to="{ name: '法律资讯' }" v-scroll-to="'#flzx'">
                                        <a-col :span="4" style="color: black">法律资讯</a-col>
                                    </router-link>
                                </a-row>

                                <!-- <a-menu
                                theme="light"
                                mode="horizontal"
                                :default-selected-keys="['5']"
                                :selectedKeys="[selectedKeys]"
                                :style="{ lineHeight: '119px' }"
                                @click="handleClick"
                              >
                                <a-menu-item key="0"
                                  ><img src="../public/img/新乾宇官网/搜索.png" alt=""
                                /></a-menu-item>
                                <a-menu-item key="1"
                                  ><a href="/#info"
                                    ><img src="../public/img/新乾宇官网/资讯.png" alt="" /></a
                                ></a-menu-item>
                                <a-menu-item disabled style="cursor: unset; font-size: 30px"
                                  >|</a-menu-item
                                >
                                <a-menu-item key="关于我们"
                                  ><router-link :to="{ name: '关于我们' }"
                                    >关于我们</router-link
                                  ></a-menu-item
                                >
                                <a-menu-item key="解决方案">
                                  <router-link :to="{ name: '解决方案' }">解决方案</router-link>
                                </a-menu-item>
                                <a-menu-item key="成功案例">
                                  <router-link :to="{ name: '成功案例' }">成功案例</router-link>
                                </a-menu-item>
                                <a-menu-item key="首页">
                                  <router-link :to="{ name: '首页' }">首页</router-link>
                                </a-menu-item>
                              </a-menu> -->
                            </a-col>
                            <a-col :span="3">
                                <div style="height: 100%; width: 15rem; line-height: 2rem">
                                    <div
                                            style="
                      height: 30%;
                      width: 100%;
                      font-size: 30px;
                      color: #363636;
                      text-align: center;
                    "
                                            v-if="webconfig"
                                            v-html="webconfig['1'].richText"
                                    >
                                        <!-- (0771)8756 2533 -->
                                    </div>
                                    <a-divider
                                            type="horizontal"
                                            style="margin: 5px 0; background-color: #363636"
                                    />
                                    <router-link :to="{ name: '关于我们' }" v-scroll-to="'#gywm'">
                                        <div
                                                style="
                        height: 30%;
                        width: 100%;
                        font-size: 16px;
                        width: 100%;
                        text-align: center;
                        background-color: #bca474;
                        color: white;
                        letter-spacing: 2px;
                      "
                                        >
                                            关于我们
                                        </div>
                                    </router-link>
                                    <!-- <a-popover placement="bottom">
                                      <template slot="content">
                                        <img
                                          style="object-fit: contain; height: 130px; width: 130px"
                                          :src="xqyQrCode"
                                        />
                                      </template>
                                      <div
                                        style="
                                          height: 30%;
                                          font-size: 16px;
                                          font-weight: 900;
                                          width: 60%;
                                        "
                                      >
                                        <a-icon type="qrcode" />官方微信公众号
                                      </div>
                                    </a-popover> -->
                                </div>
                            </a-col>
                            <a-col :span="1"></a-col>
                        </a-row>
                    </a-layout-header>
                </a-anchor>
                <!-- <div style="height: 119px"></div> -->

                <router-view></router-view>
                <footer1></footer1>
            </a-layout>
        </div>
    </a-config-provider>
</template>

<script>
    import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
    import request from "@/utils/request";
    import footer1 from "./views/footer.vue";

    export default {
        components: {footer1},
        data () {
            return {
                ShowTopBanner: true,
                logoNoneDisplay: false,
                locale: zhCN,
                selectedKeys: this.$route.meta.rootPathName,
                xqyQrCode: require("../public/img/新乾宇官网/xqyQrCode.jpg"),
                webconfig: ''
            };
        },
        methods: {
            handleClick (e) {
                console.log("click ", e);
                console.log(this.$route);
                this.selectedKeys = e.key;
            },
            scrollHander () {
                let that = this;
                let offsetHeight = this.$refs.testtop.$el?this.$refs.testtop.$el.offsetHeight:this.$refs.testtop.offsetHeight
                if (
                    document.documentElement.scrollTop < offsetHeight
                ) {
                    var VueScrollTo = require("vue-scrollto");
                    var options = {
                        container: "body",
                        easing: "ease-in",
                        lazy: false,
                        offset: 0,
                        force: true,
                        cancelable: true,
                        onStart: function () {
                            // scrolling started
                            window.removeEventListener("mousewheel", that.scrollHander);
                        },
                        onDone: function () {
                            // scrolling is done
                            that.ShowTopBanner = false;
                        },
                        onCancel: function () {
                            // scrolling has been interrupted
                            that.ShowTopBanner = false;
                        },
                        x: false,
                        y: true,
                    };
                    VueScrollTo.scrollTo("#referenceTop", 500, options);
                }
            },
        },
        created () {
            console.log(this.$route);
            this.selectedKeys = this.$route.meta.rootPathName;
            // 请求网站设置
            let that = this;
            request({
                url: "/api/GetDataEntityController",
                method: "GET",
                params: null,
            }).then((res) => {
                // console.log("res= ",res)
                console.log(res);
                if (res.data != null) {
                    let data = res.data
                    let webconfig = {}
                    data.forEach(item=>{
                        webconfig[item.id] = item
                    })
                    window.localStorage.setItem("webconfig", JSON.stringify(webconfig));
                    that.webconfig = webconfig;
                    console.log(webconfig)
                }
            });
            console.log(JSON.parse(window.localStorage.getItem("webconfig")));

            window.addEventListener("mousewheel", this.scrollHander);
        },
        beforeDestroy () {
            window.removeEventListener("mousewheel", this.scrollHander);
        },
        watch: {
            $route: function () {
                this.selectedKeys = this.$route.meta.rootPathName;
                console.log(this.$route.name != "首页");
                if (this.$route.name != "首页") {
                    this.ShowTopBanner = false;
                } else {
                    this.ShowTopBanner = true;
                }
            },
        },
    };
</script>
<style lang="scss">
    // #app {
    //   font-family: Avenir, Helvetica, Arial, sans-serif;
    //   -webkit-font-smoothing: antialiased;
    //   -moz-osx-font-smoothing: grayscale;
    //   text-align: center;
    //   color: #2c3e50;
    // }

    // #nav {
    //   padding: 30px;

    //   a {
    //     font-weight: bold;
    //     color: #2c3e50;

    //     &.router-link-exact-active {
    //       color: #42b983;
    //     }
    //   }
    // }

    .custom-body {
        box-sizing: border-box;
        min-width: 1900px;
        height: 100%;
        width: 100%;
    }

    .logoNoneDisplay {
        display: none;
    }

    #components-layout-demo-top .logo {
        // width: 500px;
        float: left;
        height: 90px;
        // position: relative;
        // top: 50%; /*偏移*/
        // margin-top: -20px;
    }

    #components-layout-demo-top .logo .logoimg {
        height: 90px;
    }

    .ant-layout {
        background: transparent !important;
    }

    .ant-layout-header {
        height: 119px !important;
        background-color: white !important;
    }

    .ant-menu-light {
        background: transparent !important;
    }

    .ant-menu-item {
        color: #363636 !important;
        float: right;
        top: 0 !important;
        border-bottom: 0px !important;
        font-size: 16px;
    }

    .ant-menu-item-active {
        color: #363636 !important;
        float: right;
        top: 0 !important;
        border-bottom: 0px !important;
        font-size: 16px;
    }

    .ant-menu-item-selected {
        float: right;
        border-bottom: 0px !important;
        background-image: unset;
        font-size: 16px;
    }

    .ant-menu-item-selected > a {
        color: #363636 !important;
    }

    .ant-menu-horizontal {
        border-bottom: 0px !important;
    }

    @keyframes uptoleave {
        0% {
            top: 0px;
        }
        99% {
            top: -200px;
        }
        100% {
            display: none;
        }
    }
</style>

