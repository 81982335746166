<template>
  <div class="home" style="overflow: hidden">
    <a-layout-content style="width: 100%">
      <!-- 品牌保证 -->
      <a-row
        :gutter="16"
        align="top"
        class="row-box-height"
        style="
          position: relative;
          height: 400px;
          padding-bottom: 30px;
          background-size: 100% 100%;
          background-color: #f4f4f4;
        "
      >
        <a-col :span="3"></a-col>
        <a-col :span="18" style="height: 100%">
          <a-row style="margin-bottom: 20px; margin-top: 20px">
            <div
              style="
                display: flex;
                margin: 30px;
                margin-bottom: 0;
                font-size: 18px;
                font-weight: 900;
                position: relative;
                color: #363636;
              "
            >
              明桂律师事务所可以为您提供的帮助：
            </div>
          </a-row>
          <a-row
            type="flex"
            justify="space-around"
            align="top"
            v-for="(item1, index1) in listTemp1"
            :key="index1"
          >
            <a-col
              :span="3"
              style="text-align: center"
              v-for="(item2, index2) in item1"
              :key="index2"
            >
              <div style="display: inline-flex">
                <a-card :bordered="false" style="background-color: unset">
                  <div style="margin: 2rem">
                    <img
                      slot="cover"
                      style="width: 55px; height: 50px"
                      alt="example"
                      :src="item2.pic"
                    />
                  </div>
                  <a-card-meta>
                    <template slot="title">
                      <div style="font-weight: 500">{{ item2.title }}</div>
                    </template>
                    <!-- <template slot="description"> {{ item2.content }} </template> -->
                  </a-card-meta>
                </a-card>
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-bottom: 20px; margin-top: 20px">
            <router-link :to="{ name: '业务领域' }" v-scroll-to="'#ywly'">
              <div
                style="
                  display: flex;
                  float: right;
                  margin: 30px 30px 0;
                  font-size: 16px;
                  font-weight: bolder;
                  position: relative;
                  color: black;
                "
              >
                查看更多>>
              </div>
            </router-link>
          </a-row>
        </a-col>
        <a-col :span="3"></a-col>
      </a-row>
      <!-- 品牌保证 -->

      <!-- 关于我们 -->
      <a-row
        id="gywm"
        :gutter="16"
        align="top"
        class="row-box-height"
        style="
          position: relative;
          height: 100%;
          margin: 30px 0;
          background-color: #ffffff;
        "
        :style="{ backgroundImage: 'url(' + gsBackgroundImage + ')' }"
      >
        <a-col
          :span="18"
          offset="3"
          style="background-color: white; height: 100%"
        >
          <a-row style="margin-bottom: 20px; margin-top: 20px">
            <a-col :span="10">
              <img v-if="webconfig" :src="webconfig[3].mediaPath" style="width: 100%" />
            </a-col>
            <a-col :span="12" offset="1">
              <div style="position: relative; height: 500px">
                <div
                  style="
                    margin: 30px;
                    font-size: 16px;
                    position: absolute;
                    top: 10%;
                    text-indent: 2em;
                    line-height: 1.8;
                  "
                  v-if="webconfig"
                  v-html="webconfig[4].richText"
                >
                  <!-- <p>
                    明桂律师事务所律业务中心，组建有法律数据分析研究中心
                    刑事辩护讲师团、民商事及行政法律事务讲师团、金融法律
                    事务部等专业化团队，不仅在各类诉讼领域持续居于全区领
                    先地位，更能在公司事务、风险防控、知识产权、信息技术
                    与互联网、物流，特别是金融、房地产与建筑工程、基础设
                    施与公用事业等方面非诉讼领域为不同需求客户提供全方位
                    高水准法律支持与服务。
                  </p>
                  <p>
                    明桂律师事务所律业务中心，组建有法律数据分析研究中心
                    刑事辩护讲师团、民商事及行政法律事务讲师团、金融法律
                    事务部等专业化团队。
                  </p> -->
                </div>
                <router-link :to="{ name: '关于我们' }" v-scroll-to="'#gywm'">
                  <div
                    style="
                      background-color: #bca474;
                      color: white;
                      position: absolute;
                      padding: 10px;
                      margin: 30px;
                      cursor: pointer;
                      width: 128px;
                      text-align: center;
                    "
                  >
                    关于我们
                  </div>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <!-- 关于我们 -->

      <!-- 团队介绍 -->
      <a-row
        :gutter="16"
        align="top"
        class="row-box-height"
        style="
          position: relative;
          height: 100%;
          margin: 30px 0;
          background-color: #f4f4f4;
        "
      >
        <a-col :span="18" offset="3" style="height: 100%">
          <a-row style="margin-bottom: 20px; margin-top: 20px">
            <a-col :span="8">
              <div style="position: relative; height: 500px">
                <div
                  style="
                    margin: 30px;
                    font-size: 20px;
                    position: absolute;
                    top: 15%;
                    line-height: 1.5rem;
                  "
                >
                  <h2 style="margin-bottom: 30px">资深专业团队</h2>
                  <p>
                    我们的律师技术娴熟，平易近人。我们的规模使我们的合作伙伴能够对每一件事给予个人关注。
                  </p>
                </div>
                <router-link :to="{ name: '团队介绍' }" v-scroll-to="'#tdjs'">
                  <div
                    style="
                      background-color: #bca474;
                      color: white;
                      position: absolute;
                      padding: 10px;
                      bottom: 20%;
                      margin: 30px;
                      cursor: pointer;
                    "
                  >
                    了解更多
                  </div>
                </router-link>
              </div>
            </a-col>
            <a-col :span="16">
              <a-row
                type="flex"
                justify="space-around"
                align="top"
                v-for="(item1, index1) in listTemp2"
                :key="index1"
              >
                <a-col
                  :span="3"
                  style="text-align: center"
                  v-for="(item2, index2) in item1"
                  :key="index2"
                >
                  <router-link
                    :to="{
                      name: '成员页',
                      params: { Lawyer: item2, fromPath },
                    }"
                  >
                    <div style="display: inline-flex">
                      <a-card :bordered="false" style="background-color: unset">
                        <div>
                          <img
                            slot="cover"
                            style="
                              width: 240px;
                              height: 240px;
                              object-fit: contain;
                            "
                            alt="example"
                            :src="item2.portrait"
                          />
                        </div>
                        <a-card-meta>
                          <template slot="title">
                            <div
                              style="
                                font-weight: 500;
                                margin-top: 10px;
                                white-space: normal;
                                word-break: break-all;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                                overflow: hidden;
                              "
                            >
                              {{ item2.name }}
                            </div>
                          </template>
                          <!-- <template slot="description"> {{ item2.content }} </template> -->
                        </a-card-meta>
                      </a-card>
                    </div>
                  </router-link>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <!-- 团队介绍 -->

      <!-- 经典案例 -->
      <a-row
        :gutter="16"
        align="top"
        class="row-box-height"
        style="
          position: relative;
          height: 100%;
          margin: 30px 0;
          background-color: #ffffff;
        "
      >
        <a-col :span="18" offset="3" style="height: 100%">
          <a-row style="margin-bottom: 20px; margin-top: 20px">
            <a-col :span="8">
              <div style="position: relative; height: 500px">
                <div
                  style="
                    margin: 30px;
                    font-size: 20px;
                    position: absolute;
                    top: 15%;
                    line-height: 1.5rem;
                  "
                >
                  <h2 style="margin-bottom: 30px">经典案例</h2>
                  <p style="margin-bottom: 0">这里显示的是事务所近期的案例。</p>
                  <p>我们的客户增长迅速--我们也在不断发展以满足客户的需求。</p>
                </div>
                <router-link :to="{ name: '经典案例' }" v-scroll-to="'#jdal'">
                  <div
                    style="
                      background-color: #bca474;
                      color: white;
                      position: absolute;
                      padding: 10px;
                      bottom: 20%;
                      margin: 30px;
                      cursor: pointer;
                    "
                  >
                    更多案例
                  </div>
                </router-link>
              </div>
            </a-col>
            <a-col :span="16">
              <a-row
                type="flex"
                justify="space-around"
                align="top"
                v-for="(item1, index1) in listTemp3"
                :key="index1"
              >
                <a-col
                  :span="3"
                  style="text-align: center"
                  v-for="(item2, index2) in item1"
                  :key="index2"
                >
                  <div style="display: inline-flex">
                    <router-link
                      :to="{
                        name: '文章页',
                        params: { article: item2, fromPath },
                      }"
                    >
                      <a-card :bordered="false" style="background-color: unset">
                        <div>
                          <img
                            slot="cover"
                            style="
                              width: 240px;
                              height: 240px;
                              object-fit: contain;
                            "
                            alt="example"
                            :src="item2.image_path"
                          />
                        </div>
                        <a-card-meta>
                          <template slot="title">
                            <div
                              style="
                                font-weight: 500;
                                float: left;
                                color: #bca474;
                                white-space: normal;
                                word-break: break-all;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                                overflow: hidden;
                              "
                            >
                              {{ item2.title }}
                            </div>
                          </template>
                          <template slot="description">
                            <div
                              style="
                                font-weight: 500;
                                text-align: left;
                                margin-top: 5px;
                                white-space: normal;
                                word-break: break-all;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                                overflow: hidden;
                              "
                            >
                              {{ filtersText(item2.content) }}
                            </div>
                          </template>
                        </a-card-meta>
                      </a-card>
                    </router-link>
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <!-- 经典案例 -->

      <!-- 法律资讯 -->
      <a-row
        id="zxzx"
        :gutter="16"
        align="top"
        class="row-box-height"
        style="
          position: relative;
          margin: 30px 0;
          background-color: #f4f4f4;
          height: 590px;
        "
      >
        <a-col :span="3"></a-col>
        <a-col :span="18">
          <a-row
            style="margin: 20px 0"
            type="flex"
            justify="space-around"
            align="middle"
          >
            <a-col
              :span="10"
              style="
              height: 100px;
              align-items: center;
              justify-content: center;
              text-align: left;
              margin：30px;
              font-size:35px;
              font-weight:border;
              color:black;
            "
            >
              法律资讯
            </a-col>
            <a-col
              :span="10"
              offset="4"
              style="
              align-items: center;
              justify-content: center;
              text-align: right;
              margin：30px;
              font-size:16px;
            "
            >
              <router-link :to="{ name: '法律资讯' }" v-scroll-to="'#flzx'">
                <a-button type="" size="large">更多资讯</a-button>
              </router-link>
            </a-col>
          </a-row>
          <a-row
            type="flex"
            justify="space-around"
            align="top"
            v-for="(item1, index1) in listTemp4"
            :key="index1"
          >
            <a-col
              :span="8"
              style="text-align: center; width: 400px"
              v-for="(item2, index2) in item1"
              :key="index2"
            >
              <div style="display: inline-flex">
                <router-link
                  :to="{
                    name: '文章页',
                    params: { article: item2, fromPath },
                  }"
                >
                  <a-card :bordered="false" style="background-color: unset">
                    <div>
                      <img
                        slot="cover"
                        style="width: 326px; height: 250px; object-fit: contain"
                        alt="example"
                        :src="item2.image_path"
                      />
                    </div>
                    <a-card-meta style="margin-top: 10px">
                      <template slot="title">
                        <div
                          style="
                            font-weight: 500;
                            float: left;
                            color: #bca474;
                            white-space: normal;
                            word-break: break-all;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            overflow: hidden;
                          "
                        >
                          {{ item2.title }}
                        </div>
                      </template>
                      <template slot="description">
                        <div
                          style="
                            font-weight: 500;
                            text-align: left;
                            margin-top: 5px;
                            white-space: normal;
                            word-break: break-all;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            overflow: hidden;
                          "
                        >
                          {{ filtersText(item2.content) }}
                        </div>
                      </template>
                    </a-card-meta>
                  </a-card>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="3"></a-col>
      </a-row>
      <!-- 法律资讯 -->
    </a-layout-content>
  </div>
</template>

<script>
/* 业务领域使用数据 */
const ourBusiness = [
  {
    id: 0,
    pic: require("../../public/img/明桂/刑事辩护.svg"),
    title: "刑事辩护",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 1,
    pic: require("../../public/img/明桂/法律顾问.svg"),
    title: "法律顾问",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 2,
    pic: require("../../public/img/明桂/婚姻家庭纠纷.svg"),
    title: "婚姻家庭纠纷",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 3,
    pic: require("../../public/img/明桂/资产重组.svg"),
    title: "资产重组",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 4,
    pic: require("../../public/img/明桂/合同纠纷.svg"),
    title: "合同纠纷",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 5,
    pic: require("../../public/img/明桂/房产纠纷.svg"),
    title: "房产纠纷",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 6,
    pic: require("../../public/img/明桂/代理公司事务.svg"),
    title: "代理公司事务",
    content: "这还有两行文字详细描述文案",
  },
];

import request from "@/utils/request";
import moment from "moment";

export default {
  name: "Home",
  data() {
    return {
      carousel: 1,

      ourBusiness, // 业务范围

      LawyersList: [], // 我们的团队(动态)
      articlesList1: [], //文章列表（经典案例）
      articlesList2: [], //文章列表（法律资讯）

      activeColor: "transparent", //资讯卡片背景色

      webconfig: "",
    };
  },
  created() {
    var that = this;

    // 获取律师列表
    request({
      url: "/api/GetLawyersController",
      method: "GET",
      params: null,
    }).then((res) => {
      // console.log("res= ",res)
      console.log(res);
      if (res.data != null) {
        res.data.forEach(function () {
          that.LawyersList = res.data;
        });
      }
    });

    // 获取经典案例列表
    request({
      url: "/api/HomeGetArticlesInfoController",
      method: "GET",
      params: { parentId: 2 },
    }).then((res) => {
      // console.log("res= ",res)
      console.log(res);
      if (res.data != null) {
        res.data.forEach(function (value) {
          var item = [];
          item.title = value.articlesName;
          item.time = moment(value.created_at).format("YYYY - MM - DD");
          item.neinId = value.id;
          item.image_path = value.image_path;
          item.content = value.content;
          item.author = value.author;
          that.articlesList1.push(item);
        });
      }
    });

    // 获取法律资讯列表
    request({
      url: "/api/HomeGetArticlesInfoController",
      method: "GET",
      params: { parentId: 1 },
    }).then((res) => {
      // console.log("res= ",res)
      console.log(res);
      if (res.data != null) {
        res.data.forEach(function (value) {
          var item = new Array();
          item.title = value.articlesName;
          item.time = moment(value.created_at).format("YYYY - MM - DD");
          item.neinId = value.id;
          item.image_path = value.image_path;
          item.content = value.content;
          item.author = value.author;
          that.articlesList2.push(item);
        });
      }
    });

    // 请求网站设置
    request({
      url: "/api/GetDataEntityController",
      method: "GET",
      params: null,
    }).then((res) => {
      // console.log("res= ",res)
      console.log(res);
      if (res.data != null) {
        window.localStorage.setItem("webconfig", JSON.stringify(res.data));
        that.webconfig = res.data;
      }
    });
    console.log(JSON.parse(window.localStorage.getItem("webconfig")));
  },
  methods: {
    carousel1goto(e) {
      console.log(e.currentTarget.id);
      this.$refs.varousel.goTo(e.currentTarget.id, false);
    },
    callback(key) {
      console.log(key);
    },
    mouseover(e) {
      console.log(e);
      e.currentTarget.style.backgroundColor = "rgba(194, 194, 194,0.3)";
    },
    mouseleave(e) {
      console.log(e);
      e.currentTarget.style.backgroundColor = "transparent";
    },
    // 文本内容html标签过滤
    filtersText(val) {
      // 要判断一下,如果是空就返回空字符串,不然会报错
      if (val != null && val != "") {
        var reg = /[\u4e00-\u9fa5]/g;
        var names = val.match(reg);
        val = names.join("");
        return val.substr(0, 150); //获取富文本文字内容的前50个字符
      } else return "";
    },
    getWindowX() {
      console.log(window.pageXOffset);
    },
  },
  computed: {
    // 直接使用listTemp就可以
    listTemp1: function () {
      var list = this.ourBusiness; //当前要拆分的数组
      var arrTemp = [];
      var index = 0;
      var sectionCount = 7; //单页或者单行数量
      for (var i = 0; i < list.length; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp; //分页数组
    },

    // 团队照片
    listTemp2: function () {
      var list = this.LawyersList; //当前要拆分的数组
      var arrTemp = [];
      var index = 0;
      var sectionCount = 3; //单页或者单行数量
      // 限制6个元素
      for (var i = 0; i < list.length && i < 6; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp; //分页数组
    },

    // 经典案例
    listTemp3: function () {
      var list = this.articlesList1; //当前要拆分的数组
      var arrTemp = [];
      var index = 0;
      var sectionCount = 3; //单页或者单行数量
      // 限制6个元素
      for (var i = 0; i < list.length && i < 6; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp; //分页数组
    },

    // 法律资讯
    listTemp4: function () {
      var list = this.articlesList2; //当前要拆分的数组
      var arrTemp = [];
      var index = 0;
      var sectionCount = 3; //单页或者单行数量
      // 限制6个元素
      for (var i = 0; i < list.length && i < 3; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp; //分页数组
    },

    // 分页计算，多组
    listTemp: function () {
      var list = this.picturebag3;
      var arrTemp = [];
      var index = 0;
      var sectionCount = 6;
      for (var i = 0; i < list.length; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp;
    },
  },
};
</script>

<style scoped>
@import "../../public/css/style.css";

.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 100%;
  width: 120%;
  line-height: 0;
  background: #364d79;
  overflow: hidden;
  /* border-radius: 15px; */
}

.ant-carousel >>> .slick-dots {
  display: none !important;
  height: auto;
  left: 13%;
  margin: 0px auto;
  text-align: left;
  bottom: 110px;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 50px;
  height: 50px;
  font-size: 50px;
  color: rgb(194, 194, 194);
  opacity: 0.4;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  color: rgb(194, 194, 194);
  opacity: 1;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}

.ant-carousel[data-v-f3f3eg9] >>> .slick-slide {
  text-align: center;
  height: 100%;
  width: 100%;
  line-height: 0;
  background: transparent;
  overflow: hidden;
  /* border-radius: 15px; */
}

.ant-carousel[data-v-f3f3eg9] >>> .slick-dots1 {
  display: block !important;
  height: auto;
  margin: 0px auto;
  text-align: center;
  bottom: -50px;
  left: 0;
}

.ant-carousel[data-v-f3f3eg9] >>> .slick-dots li button {
  display: block;
  width: 24px;
  height: 4px;
  padding: 0;
  color: #999b9e !important;
  font-size: 0;
  background: #999b9e !important;
  border: 0;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}

.ant-carousel[data-v-f3f3eg9] >>> .slick-dots li.slick-active button {
  color: #0051da !important;
  width: 48px;
  background: #0051da !important;
  opacity: 1;
}

.ant-carousel[data-v-f3f3eg9] >>> .custom-slick-arrow {
  width: 50px;
  height: 50px;
  font-size: 50px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel[data-v-f3f3eg9] >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel[data-v-f3f3eg9] >>> .custom-slick-arrow:hover {
  opacity: 1;
}

.ant-carousel[data-v-f3f3eg9] >>> .slick-slide h3 {
  color: #fff;
}

.buttom-bar-item {
  height: 100%;
  /* 居中 */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.buttom-bar-item-img {
  width: 80%;
  height: 70%;
}

.buttom-bar-item-words {
  width: 80%;
  height: 30%;
  margin: auto;
  overflow: hidden;
  font-size: 20px;
  text-overflow: ellipsis;
}
.radiusleft {
  border-radius: 15px 0 0 15px;
}

.radiusright {
  border-radius: 0 15px 15px 0;
}

.warp {
  width: 100%;
  height: 120px;
  margin: 0 auto;
  overflow: hidden;
}
.warp ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}
.warp ul.ul-item {
  display: flex;
}
.warp ul.ul-item .li-item {
  font-size: 5rem;
  font-weight: 100;
  z-index: 0;
  height: 120px;
  margin-right: 10px;
  line-height: 120px;
  text-align: center;
}

.warp1 {
  width: 100rem;
  height: 60px;
  margin: 0 auto;
  float: left;
  overflow: hidden;
}
.warp1 ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}
.warp1 ul.ul-item {
  float: left;
  width: 20rem;
}
.warp1 ul.ul-item .li-item {
  color: white;
  float: left;
  height: 60px;
  line-height: 60px;
  font-size: 1rem;
  font-weight: 100;
  z-index: 0;
  width: 100rem;
  margin-right: 10px;
  text-align: left;
  overflow: hidden;
}
</style>
