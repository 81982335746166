<template>
  <div>
    <a-row
      style="height: 40px; background-color: #f1ece3"
      type="flex"
      justify="space-around"
      align="middle"
    >
      欢迎您的咨询！
    </a-row>
    <a-layout-footer style="background-color: #e6e6e6">
      <a-row type="flex" justify="center" align="top">
        <!-- 第1栏 -->
        <a-col :span="4">
          <div style="height: 10px; border-top: 1px #bca474 solid"></div>
          <div>
            <a-card :bordered="false" style="background-color: unset">
              <div>
                <!-- <img
                  slot="cover"
                  style="width: 326px; height: 170px"
                  alt="example"
                  :src="item2.pic"
                /> -->
              </div>
              <a-card-meta>
                <template slot="title">
                  <h1
                    style="
                      width: 100%;
                      font-weight: 500;
                      float: left;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    明桂律师事务所
                  </h1>
                </template>
                <template slot="description">
                  <div
                    style="
                      font-weight: 500;
                      text-align: left;
                      margin-top: 5px;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 2;
                      overflow: hidden;
                    "
                  >
                    <a-row>
                      <a-col :span="12" class="colorblack">
                        <router-link
                          :to="{ name: '首页' }"
                          v-scroll-to="'#top'"
                        >
                          <p>首页</p>
                        </router-link>
                        <router-link
                          :to="{ name: '业务领域' }"
                          v-scroll-to="'#ywly'"
                        >
                          <p>业务领域</p>
                        </router-link>
                        <router-link
                          :to="{ name: '团队介绍' }"
                          v-scroll-to="'#tdjs'"
                        >
                          <p>团队介绍</p>
                        </router-link>
                        <router-link
                          :to="{ name: '经典案例' }"
                          v-scroll-to="'#jdal'"
                        >
                          <p>经典案例</p>
                        </router-link>
                      </a-col>
                      <a-col :span="12" class="colorblack">
                        <router-link
                          :to="{ name: '法律资讯' }"
                          v-scroll-to="'#flzx'"
                        >
                          <p>法律资讯</p>
                        </router-link>
                        <router-link
                          :to="{ name: '关于我们' }"
                          v-scroll-to="'#gywm'"
                        >
                          <p>关于我们</p>
                        </router-link>
                      </a-col>
                    </a-row>
                  </div>
                </template>
              </a-card-meta>
            </a-card>
          </div>
        </a-col>
        <!-- 第1栏 -->
        <a-col :span="2"></a-col>
        <!-- 第2栏 -->
        <a-col :span="4">
          <div style="height: 10px; border-top: 1px #bca474 solid"></div>
          <div>
            <a-card :bordered="false" style="background-color: unset">
              <div>
                <!-- <img
                  slot="cover"
                  style="width: 326px; height: 170px"
                  alt="example"
                  :src="item2.pic"
                /> -->
              </div>
              <a-card-meta>
                <template slot="title">
                  <h1
                    style="
                      width: 100%;
                      font-weight: 500;
                      float: left;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    联系我们
                  </h1>
                </template>
                <template slot="description">
                  <div
                    style="
                      font-weight: 500;
                      text-align: left;
                      margin-top: 5px;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 2;
                      overflow: hidden;
                    "
                  >
                    <a-row>
                      <a-col :span="24" v-if="webconfig" v-html="webconfig[5].richText">
                        <!-- <p>电话：0771-8756 2533</p>
                        <p>传真：0771-8756 2533</p>
                        <p>邮编：530012</p>
                        <p>
                          地址：广西南宁市兴宁区民族路108号不知道 几号办公楼
                        </p> -->
                      </a-col>
                    </a-row>
                  </div>
                </template>
              </a-card-meta>
            </a-card>
          </div>
        </a-col>
        <!-- 第2栏 -->
        <a-col :span="2"></a-col>
        <!-- 第3栏 -->
        <a-col :span="4">
          <div style="height: 10px; border-top: 1px #bca474 solid"></div>
          <div>
            <a-card :bordered="false" style="background-color: unset">
              <div>
                <!-- <img
                  slot="cover"
                  style="width: 326px; height: 170px"
                  alt="example"
                  :src="item2.pic"
                /> -->
              </div>
              <a-card-meta>
                <template slot="title">
                  <h1
                    style="
                      width: 100%;
                      font-weight: 500;
                      float: left;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    业务范围
                  </h1>
                </template>
                <template slot="description">
                  <div
                    style="
                      font-weight: 500;
                      text-align: left;
                      margin-top: 5px;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 2;
                      overflow: hidden;
                    "
                  >
                    <a-row>
                      <a-col :span="24">
                        <p
                          style="
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 7;
                            overflow: hidden;
                          "
                          v-if="webconfig"
                          v-html="webconfig[6].richText"
                        >
                          <!-- 代理公司事务、合同纠纷 婚姻家庭纠纷、刑事辩护
                          房地产纠纷、资产重组 担任企事业法律顾问。 -->
                        </p>
                      </a-col>
                    </a-row>
                  </div>
                </template>
              </a-card-meta>
            </a-card>
          </div>
        </a-col>
        <!-- 第3栏 -->
        <a-col :span="2"></a-col>
        <!-- 第4栏 -->
        <a-col :span="4">
          <div style="height: 10px; border-top: 1px #bca474 solid"></div>
          <div>
            <a-card :bordered="false" style="background-color: unset">
              <div>
                <!-- <img
                  slot="cover"
                  style="width: 326px; height: 170px"
                  alt="example"
                  :src="item2.pic"
                /> -->
              </div>
              <a-card-meta>
                <template slot="title">
                  <h1
                    style="
                      width: 100%;
                      font-weight: 500;
                      float: left;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    官方微信
                  </h1>
                </template>
                <template slot="description">
                  <div
                    style="
                      font-weight: 500;
                      text-align: left;
                      margin-top: 5px;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 2;
                      overflow: hidden;
                    "
                  >
                    <a-row>
                      <a-col :span="24">
                        <img v-if="webconfig"
                          :src="webconfig[7].mediaPath"
                          alt=""
                          style="height: 50%; width: 50%"
                        />
                      </a-col>
                    </a-row>
                  </div>
                </template>
              </a-card-meta>
            </a-card>
          </div>
        </a-col>
        <!-- 第4栏 -->
      </a-row>
      <div style="height: 10px; border-top: 1px rgba(0, 0, 0, 0.1) solid"></div>
      <a-row>
        <a-col :span="18" :offset="3">
          <a-row style="margin: 10px">
            <div>
              <p align="center" style="margin: auto">
                <a style="color: black" href=" " target="_blank" v-if="webconfig">{{
                  webconfig[1].richText
                }}</a>
              </p>
            </div>
          </a-row>
        </a-col>
      </a-row>
    </a-layout-footer>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      xqyQrCode: require("../../public/img/新乾宇官网/xqyQrCode.jpg"),
      webconfig: "",
    };
  },
  methods: {
    callback(key) {
      console.log(key);
    },
  },
  created() {
    let that = this;
    request({
      url: "/api/GetDataEntityController",
      method: "GET",
      params: null,
    }).then((res) => {
      // console.log("res= ",res)
      console.log(res);
      if (res.data != null) {
        window.localStorage.setItem("webconfig", JSON.stringify(res.data));
        that.webconfig = res.data;
      }
    });
    console.log(JSON.parse(window.localStorage.getItem("webconfig")));
  },
};
</script>

<style scoped>
a:active,
a:hover {
  zoom: 1.1;
}

.colorblack >>> a {
  color: black;
}
</style>
